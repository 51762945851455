.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: "proxima-nova";
  src: url("https://use.typekit.net/af/b683e3/00000000000000003b9b306c/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3") format("woff2"), url("https://use.typekit.net/af/b683e3/00000000000000003b9b306c/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3") format("woff"), url("https://use.typekit.net/af/b683e3/00000000000000003b9b306c/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 900;
}

@font-face {
  font-family: "proxima-nova";
  src: url("https://use.typekit.net/af/d32834/00000000000000003b9b306d/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3") format("woff2"), url("https://use.typekit.net/af/d32834/00000000000000003b9b306d/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3") format("woff"), url("https://use.typekit.net/af/d32834/00000000000000003b9b306d/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3") format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 900;
}

@font-face {
  font-family: "proxima-nova";
  src: url("https://use.typekit.net/af/949f99/00000000000000003b9b3068/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"), url("https://use.typekit.net/af/949f99/00000000000000003b9b3068/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"), url("https://use.typekit.net/af/949f99/00000000000000003b9b3068/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: "proxima-nova";
  src: url("https://use.typekit.net/af/4c4052/00000000000000003b9b3069/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff2"), url("https://use.typekit.net/af/4c4052/00000000000000003b9b3069/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff"), url("https://use.typekit.net/af/4c4052/00000000000000003b9b3069/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 700;
}

@font-face {
  font-family: "proxima-nova";
  src: url("https://use.typekit.net/af/d82519/00000000000000003b9b306a/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n8&v=3") format("woff2"), url("https://use.typekit.net/af/d82519/00000000000000003b9b306a/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n8&v=3") format("woff"), url("https://use.typekit.net/af/d82519/00000000000000003b9b306a/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n8&v=3") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 800;
}

@font-face {
  font-family: "proxima-nova";
  src: url("https://use.typekit.net/af/3e6df8/00000000000000003b9b306b/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i8&v=3") format("woff2"), url("https://use.typekit.net/af/3e6df8/00000000000000003b9b306b/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i8&v=3") format("woff"), url("https://use.typekit.net/af/3e6df8/00000000000000003b9b306b/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i8&v=3") format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 800;
}

@font-face {
  font-family: "proxima-nova";
  src: url("https://use.typekit.net/af/576d53/00000000000000003b9b3066/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("woff2"), url("https://use.typekit.net/af/576d53/00000000000000003b9b3066/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("woff"), url("https://use.typekit.net/af/576d53/00000000000000003b9b3066/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: "proxima-nova";
  src: url("https://use.typekit.net/af/f7d492/00000000000000003b9b3067/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i6&v=3") format("woff2"), url("https://use.typekit.net/af/f7d492/00000000000000003b9b3067/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i6&v=3") format("woff"), url("https://use.typekit.net/af/f7d492/00000000000000003b9b3067/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i6&v=3") format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 600;
}

@font-face {
  font-family: "proxima-nova";
  src: url("https://use.typekit.net/af/705e94/00000000000000003b9b3062/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"), url("https://use.typekit.net/af/705e94/00000000000000003b9b3062/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"), url("https://use.typekit.net/af/705e94/00000000000000003b9b3062/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "proxima-nova";
  src: url("https://use.typekit.net/af/5c70f2/00000000000000003b9b3063/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff2"), url("https://use.typekit.net/af/5c70f2/00000000000000003b9b3063/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff"), url("https://use.typekit.net/af/5c70f2/00000000000000003b9b3063/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: "proxima-nova";
  src: url("https://use.typekit.net/af/6e816b/00000000000000003b9b3064/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("woff2"), url("https://use.typekit.net/af/6e816b/00000000000000003b9b3064/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("woff"), url("https://use.typekit.net/af/6e816b/00000000000000003b9b3064/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: "proxima-nova";
  src: url("https://use.typekit.net/af/5b5251/00000000000000003b9b3065/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i5&v=3") format("woff2"), url("https://use.typekit.net/af/5b5251/00000000000000003b9b3065/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i5&v=3") format("woff"), url("https://use.typekit.net/af/5b5251/00000000000000003b9b3065/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i5&v=3") format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 500;
}


body, h1, h2, h3, h4, h5, h6, html {
  font-family: proxima-nova, Arial, Helvetica Neue, Helvetica, sans-serif;
}

/*! CSS Used from: https://myautoshop.co.nz/css/app.css?id=46f27acc8a847ed404d4 */
button {
  overflow: visible;
}

a {
  background-color: transparent;
}

strong {
  font-weight: bolder;
}

img {
  border-style: none;
}

button {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}

button {
  text-transform: none;
}

button {
  -webkit-appearance: button;
}

button::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring {
  outline: 1px dotted ButtonText;
}

* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

::-moz-selection {
  background: #fecb0a;
  color: #fff;
}

::-moz-selection, ::selection {
  background: #fecb0a;
  color: #fff;
}

::-moz-selection {
  background: #fecb0a;
  color: #fff;
}

::selection {
  background: #fecb0a;
  color: #fff;
}

img {
  display: inline-block;
  max-width: 100%;
  width: auto \9;
  height: auto;
}

a, li {
  margin: 0 0 15px;
  font-size: 14px;
  line-height: 24px;
  color: #9b9b9b;
}

li a {
  font-size: 1em;
}

a {
  margin: 0;
  font-weight: 400;
  text-decoration: none;
  color: #fecb0a;
  /*-webkit-transition: all .5s ease-in-out;*/
  /*transition: all .5s ease-in-out;*/
}

a:active, a:focus, a:hover {
  color: #caa001;
}

strong {
  font-weight: 700;
}

ul li {
  /*position: relative;*/
  /*list-style-type: none;*/
  /*text-indent: 0;*/
  /*margin: 0 0 5px;*/
  /*padding: 0 0 0 20px;*/
  /*font-size: 16px;*/
  /*font-weight: 500;*/
  /*line-height: 28px;*/
  color: #191919;
}

/*ul li:before {*/
/*    content: "";*/
/*    position: absolute;*/
/*    top: 10px;*/
/*    left: 0;*/
/*    width: 7px;*/
/*    height: 7px;*/
/*    background: #9b9b9b;*/
/*    border-radius: 50%;*/
/*}*/

button {
  outline: none;
  border: none;
  background: transparent;
  border-radius: 0;
}

.button, a.button {
  display: inline-block;
  height: 44px;
  min-width: 140px;
  padding: 0 18px;
  font-family: proxima-nova, Arial, Helvetica Neue, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 44px;
  text-decoration: none;
  text-align: center;
  color: #191919;
  cursor: pointer;
  background: #fff;
  border: 1px solid #fecb0a;
  -webkit-box-shadow: 0 1px 5px hsla(0, 0%, 61%, .25);
  box-shadow: 0 1px 5px hsla(0, 0%, 61%, .25);
  border-radius: 4px;
  /*-webkit-transition: all .4s ease-in-out;*/
  /*transition: all .4s ease-in-out;*/
}

.button:active, .button:focus, .button:hover, a.button:active, a.button:focus, a.button:hover {
  color: #fecb0a;
}

* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

@media (min-width: 1022px) {
  .mobile {
    display: none !important;
  }
}

::-webkit-input-placeholder {
  font-family: proxima-nova, Arial, Helvetica Neue, Helvetica, sans-serif;
  font-size: 16px;
  color: #9b9b9b;
}

::-moz-placeholder {
  font-family: proxima-nova, Arial, Helvetica Neue, Helvetica, sans-serif;
  font-size: 16px;
  color: #9b9b9b;
}

::-ms-input-placeholder {
  font-family: proxima-nova, Arial, Helvetica Neue, Helvetica, sans-serif;
  font-size: 16px;
  color: #9b9b9b;
}

::placeholder {
  font-family: proxima-nova, Arial, Helvetica Neue, Helvetica, sans-serif;
  font-size: 16px;
  color: #9b9b9b;
}

@media print {
  header {
    display: none !important;
  }
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

/*! CSS Used from: Embedded */
.site-header[data-v-7cbe19f6] {
  position: relative;
  width: 100%;
  height: 80px;
  z-index: 99;
  background: #fff;
}

@media (min-width: 1022px) {
  .site-header[data-v-7cbe19f6] {
    position: fixed;
    top: 0;
    left: 0;
    height: 80px;
    border-bottom: 1px solid #d8d8d8;
  }
}

.site-header .wrap[data-v-7cbe19f6] {
  position: relative;
  width: 100%;
  padding: 0 20px;
}

.site-header .logo[data-v-7cbe19f6] {
  position: relative;
  top: 23px;
  width: 242px;
  -webkit-transition: none;
  transition: none;
}

@media (min-width: 1022px) {
  .site-header .logo[data-v-7cbe19f6] {
    position: absolute;
    left: 20px;
  }
}

.site-header .logo img[data-v-7cbe19f6] {
  height: 34px;
}

.site-header nav[data-v-7cbe19f6] {
  position: fixed;
  top: 2px;
  right: -100%;
  width: calc(100% - 80px);
  height: 100vh;
  text-align: left;
  background: #fff;
  -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, .5);
  box-shadow: 0 2px 4px rgba(0, 0, 0, .5);
  z-index: 999;
  -webkit-transition: right .25s ease-in-out;
  transition: right .25s ease-in-out;
}

@media (min-width: 1022px) {
  .site-header nav[data-v-7cbe19f6] {
    position: absolute;
    right: 30px;
    display: block !important;
    width: auto;
    height: auto;
    text-align: right;
    background: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}

.site-header nav ul[data-v-7cbe19f6] {
  padding: 80px 15px 0 20px;
}

@media (min-width: 1022px) {
  .site-header nav ul[data-v-7cbe19f6] {
    padding: 0;
  }
}

.site-header nav li[data-v-7cbe19f6] {
  display: inline-block;
  position: relative;
  margin: 0;
  padding: 0;
  line-height: 44px;
}

.site-header nav li[data-v-7cbe19f6]:before {
  display: none;
}

@media (max-width: 1022px) {

  .button-li {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
  .site-header nav li[data-v-7cbe19f6] {
     width: 100%;
     margin: 0;
     padding: 20px 0;
     line-height: 24px;
     /*border-bottom: 1px solid #eee;*/
   }


  .site-header nav li[data-v-7cbe19f6]:last-of-type {
    border: none;
  }


}

.site-header nav a[data-v-7cbe19f6] {
  display: inline-block;
  margin-left: 0px;
  /*font-size: 14px;*/
  color: #000;
}

.site-header nav a strong[data-v-7cbe19f6] {
  font-size: 16px;
  font-weight: 800;
  letter-spacing: .5px;
}

.site-header nav a[data-v-7cbe19f6]:hover {
  opacity: .7;
}

.site-header .hamburger[data-v-7cbe19f6] {
  position: absolute;
  top: 30px;
  right: 20px;
  display: inline-block;
  width: 45px;
  height: 35px;
  padding: 20px;
  font-size: 0;
  background: transparent;
  outline: none;
  -webkit-transition: right .25s ease-in-out;
  transition: right .25s ease-in-out;
}

.site-header .hamburger span[data-v-7cbe19f6] {
  position: absolute;
  top: 0;
  left: 0;
  width: 25px;
  height: 2px;
  background: #000;
  -webkit-transition: all .25s ease-in-out;
  transition: all .25s ease-in-out;
}

.site-header .hamburger span[data-v-7cbe19f6]:nth-of-type(2) {
  top: 7.5px;
}

.site-header .hamburger span[data-v-7cbe19f6]:nth-of-type(3) {
  top: 15px;
}

.site-header .hamburger[data-v-7cbe19f6]:focus {
  outline: none;
}

/*! CSS Used fontfaces */
@font-face {
  font-family: "proxima-nova";
  src: url("https://use.typekit.net/af/b683e3/00000000000000003b9b306c/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3") format("woff2"), url("https://use.typekit.net/af/b683e3/00000000000000003b9b306c/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3") format("woff"), url("https://use.typekit.net/af/b683e3/00000000000000003b9b306c/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 900;
}

@font-face {
  font-family: "proxima-nova";
  src: url("https://use.typekit.net/af/d32834/00000000000000003b9b306d/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3") format("woff2"), url("https://use.typekit.net/af/d32834/00000000000000003b9b306d/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3") format("woff"), url("https://use.typekit.net/af/d32834/00000000000000003b9b306d/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3") format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 900;
}

@font-face {
  font-family: "proxima-nova";
  src: url("https://use.typekit.net/af/949f99/00000000000000003b9b3068/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"), url("https://use.typekit.net/af/949f99/00000000000000003b9b3068/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"), url("https://use.typekit.net/af/949f99/00000000000000003b9b3068/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: "proxima-nova";
  src: url("https://use.typekit.net/af/4c4052/00000000000000003b9b3069/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff2"), url("https://use.typekit.net/af/4c4052/00000000000000003b9b3069/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff"), url("https://use.typekit.net/af/4c4052/00000000000000003b9b3069/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 700;
}

@font-face {
  font-family: "proxima-nova";
  src: url("https://use.typekit.net/af/d82519/00000000000000003b9b306a/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n8&v=3") format("woff2"), url("https://use.typekit.net/af/d82519/00000000000000003b9b306a/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n8&v=3") format("woff"), url("https://use.typekit.net/af/d82519/00000000000000003b9b306a/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n8&v=3") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 800;
}

@font-face {
  font-family: "proxima-nova";
  src: url("https://use.typekit.net/af/3e6df8/00000000000000003b9b306b/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i8&v=3") format("woff2"), url("https://use.typekit.net/af/3e6df8/00000000000000003b9b306b/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i8&v=3") format("woff"), url("https://use.typekit.net/af/3e6df8/00000000000000003b9b306b/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i8&v=3") format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 800;
}

@font-face {
  font-family: "proxima-nova";
  src: url("https://use.typekit.net/af/576d53/00000000000000003b9b3066/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("woff2"), url("https://use.typekit.net/af/576d53/00000000000000003b9b3066/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("woff"), url("https://use.typekit.net/af/576d53/00000000000000003b9b3066/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: "proxima-nova";
  src: url("https://use.typekit.net/af/f7d492/00000000000000003b9b3067/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i6&v=3") format("woff2"), url("https://use.typekit.net/af/f7d492/00000000000000003b9b3067/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i6&v=3") format("woff"), url("https://use.typekit.net/af/f7d492/00000000000000003b9b3067/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i6&v=3") format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 600;
}

@font-face {
  font-family: "proxima-nova";
  src: url("https://use.typekit.net/af/705e94/00000000000000003b9b3062/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"), url("https://use.typekit.net/af/705e94/00000000000000003b9b3062/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"), url("https://use.typekit.net/af/705e94/00000000000000003b9b3062/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "proxima-nova";
  src: url("https://use.typekit.net/af/5c70f2/00000000000000003b9b3063/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff2"), url("https://use.typekit.net/af/5c70f2/00000000000000003b9b3063/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff"), url("https://use.typekit.net/af/5c70f2/00000000000000003b9b3063/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: "proxima-nova";
  src: url("https://use.typekit.net/af/6e816b/00000000000000003b9b3064/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("woff2"), url("https://use.typekit.net/af/6e816b/00000000000000003b9b3064/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("woff"), url("https://use.typekit.net/af/6e816b/00000000000000003b9b3064/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: "proxima-nova";
  src: url("https://use.typekit.net/af/5b5251/00000000000000003b9b3065/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i5&v=3") format("woff2"), url("https://use.typekit.net/af/5b5251/00000000000000003b9b3065/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i5&v=3") format("woff"), url("https://use.typekit.net/af/5b5251/00000000000000003b9b3065/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i5&v=3") format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 500;
}

/*footer*/


/*! CSS Used from: https://myautoshop.co.nz/css/app.css?id=46f27acc8a847ed404d4 */
a {
  background-color: transparent;
}

img {
  border-style: none;
}

* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

::-moz-selection {
  background: #fecb0a;
  color: #fff;
}

::-moz-selection, ::selection {
  background: #fecb0a;
  color: #fff;
}

::-moz-selection {
  background: #fecb0a;
  color: #fff;
}

::selection {
  background: #fecb0a;
  color: #fff;
}

img {
  display: inline-block;
  max-width: 100%;
  width: auto \9;
  height: auto;
}

[class*=" icon-"] {
  font-family: icomoon;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-facebook:before {
  content: "\E93F";
}

.icon-instagram2:before {
  content: "\F16D";
}

.icon-linkedin:before {
  content: "\E946";
}

.icon-twitter:before {
  content: "\F099";
}

a, p {
  margin: 0 0 15px;
  font-size: 14px;
  line-height: 24px;
  color: #9b9b9b;
}



p a {
  font-size: 1em;
}

a {
  margin: 0;
  font-weight: 400;
  text-decoration: none;
  color: #fecb0a;
  /*-webkit-transition: all .5s ease-in-out;*/
  /*transition: all .5s ease-in-out;*/
}

a:active, a:focus, a:hover {
  color: #caa001;
}

* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.content-max-width {
  width: 100%;
  padding: 0 20px;
}

.content-max-width {
  position: relative;
  max-width: 1210px;
  margin: 0 auto;
}

@media (min-width: 1022px) {
  .mobile {
    display: none !important;
  }
}

.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
}

.flex.flex-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

::-webkit-input-placeholder {
  font-family: proxima-nova, Arial, Helvetica Neue, Helvetica, sans-serif;
  font-size: 16px;
  color: #9b9b9b;
}

::-moz-placeholder {
  font-family: proxima-nova, Arial, Helvetica Neue, Helvetica, sans-serif;
  font-size: 16px;
  color: #9b9b9b;
}

::-ms-input-placeholder {
  font-family: proxima-nova, Arial, Helvetica Neue, Helvetica, sans-serif;
  font-size: 16px;
  color: #9b9b9b;
}

::placeholder {
  font-family: proxima-nova, Arial, Helvetica Neue, Helvetica, sans-serif;
  font-size: 16px;
  color: #9b9b9b;
}

@media print {
  footer {
    display: none !important;
  }
}

/*! CSS Used from: Embedded */
.site-footer[data-v-77c53c4d] {
  position: relative;
  width: 100%;
  min-height: 310px;
  padding: 30px 0;
  background: #fff;
}

@media (min-width: 1022px) {
  .site-footer[data-v-77c53c4d] {
    min-height: 310px;
    padding: 60px 0 0;
  }
}

.site-footer .contact[data-v-77c53c4d] {
  max-width: 320px;
  margin: 0 auto 45px;
  text-align: center;
}

@media (min-width: 1022px) {
  .site-footer .contact[data-v-77c53c4d] {
    margin: 0 0 79px;
    text-align: left;
  }
}

.site-footer .contact a.email[data-v-77c53c4d] {
  display: block;
  margin-bottom: 8px;
  font-size: 22px;
  font-weight: 800;
  line-height: 26px;
  color: #fecb0a;
}

@media (min-width: 1022px) {
  .site-footer .contact a.email[data-v-77c53c4d] {
    font-size: 30px;
    line-height: 33px;
    letter-spacing: .3px;
  }
}

.site-footer .contact a.phone[data-v-77c53c4d] {
  font-size: 16px;
  font-weight: 800;
  letter-spacing: .5px;
  text-transform: uppercase;
  color: #191919;
}

.site-footer .left[data-v-77c53c4d] {
  width: 100%;
  text-align: center;
}

@media (min-width: 1022px) {
  .site-footer .left[data-v-77c53c4d] {
    width: auto;
    text-align: left;
  }
}

.site-footer .left .social[data-v-77c53c4d] {
  margin-bottom: 18px;
}

.site-footer .left .social a[data-v-77c53c4d] {
  font-size: 22px;
  margin-right: 22px;
}

.site-footer .left a[data-v-77c53c4d], .site-footer .left p[data-v-77c53c4d] {
  width: 100%;
  margin: 0;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  text-align: center;
  color: #303030;
}

@media (min-width: 1022px) {
  .site-footer .left a[data-v-77c53c4d], .site-footer .left p[data-v-77c53c4d] {
    width: auto;
    text-align: left;
  }
}

.site-footer .left a.bpb[data-v-77c53c4d] {
  position: relative;
  padding: 0 18px;
}

.site-footer .left a.bpb[data-v-77c53c4d]:before {
  content: "";
  position: relative;
  top: -2px;
  left: -8px;
  display: inline-block;
  width: 5px;
  height: 5px;
  background-color: #fecb0a;
  border-radius: 50%;
}

.site-footer .right[data-v-77c53c4d] {
  width: 100%;
  margin-top: 40px;
  text-align: center;
}

@media (min-width: 1022px) {
  .site-footer .right[data-v-77c53c4d] {
    width: auto;
    margin-top: 0;
    text-align: right;
  }
}

.site-footer .right img[data-v-77c53c4d] {
  height: 50px;
  margin: 0 10px;
}

@media (min-width: 1022px) {
  .site-footer .right img[data-v-77c53c4d] {
    height: 56px;
    margin: 0 0 0 26px;
  }
}

/*! CSS Used fontfaces */
@font-face {
  font-family: icomoon;
  src: url("https://myautoshop.co.nz/fonts/icomoon.eot?l1en53");
  src: url("https://myautoshop.co.nz/fonts/icomoon.eot#iefixl1en53") format("embedded-opentype"), url("https://myautoshop.co.nz/fonts/icomoon.ttf?l1en53") format("truetype"), url("https://myautoshop.co.nz/fonts/icomoon.woff?l1en53") format("woff"), url("https://myautoshop.co.nz/fonts/icomoon.svg?l1en53#icomoon") format("svg");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "proxima-nova";
  src: url("https://use.typekit.net/af/b683e3/00000000000000003b9b306c/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3") format("woff2"), url("https://use.typekit.net/af/b683e3/00000000000000003b9b306c/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3") format("woff"), url("https://use.typekit.net/af/b683e3/00000000000000003b9b306c/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 900;
}

@font-face {
  font-family: "proxima-nova";
  src: url("https://use.typekit.net/af/d32834/00000000000000003b9b306d/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3") format("woff2"), url("https://use.typekit.net/af/d32834/00000000000000003b9b306d/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3") format("woff"), url("https://use.typekit.net/af/d32834/00000000000000003b9b306d/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3") format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 900;
}

@font-face {
  font-family: "proxima-nova";
  src: url("https://use.typekit.net/af/949f99/00000000000000003b9b3068/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"), url("https://use.typekit.net/af/949f99/00000000000000003b9b3068/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"), url("https://use.typekit.net/af/949f99/00000000000000003b9b3068/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: "proxima-nova";
  src: url("https://use.typekit.net/af/4c4052/00000000000000003b9b3069/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff2"), url("https://use.typekit.net/af/4c4052/00000000000000003b9b3069/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff"), url("https://use.typekit.net/af/4c4052/00000000000000003b9b3069/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 700;
}

@font-face {
  font-family: "proxima-nova";
  src: url("https://use.typekit.net/af/d82519/00000000000000003b9b306a/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n8&v=3") format("woff2"), url("https://use.typekit.net/af/d82519/00000000000000003b9b306a/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n8&v=3") format("woff"), url("https://use.typekit.net/af/d82519/00000000000000003b9b306a/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n8&v=3") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 800;
}

@font-face {
  font-family: "proxima-nova";
  src: url("https://use.typekit.net/af/3e6df8/00000000000000003b9b306b/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i8&v=3") format("woff2"), url("https://use.typekit.net/af/3e6df8/00000000000000003b9b306b/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i8&v=3") format("woff"), url("https://use.typekit.net/af/3e6df8/00000000000000003b9b306b/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i8&v=3") format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 800;
}

@font-face {
  font-family: "proxima-nova";
  src: url("https://use.typekit.net/af/576d53/00000000000000003b9b3066/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("woff2"), url("https://use.typekit.net/af/576d53/00000000000000003b9b3066/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("woff"), url("https://use.typekit.net/af/576d53/00000000000000003b9b3066/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: "proxima-nova";
  src: url("https://use.typekit.net/af/f7d492/00000000000000003b9b3067/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i6&v=3") format("woff2"), url("https://use.typekit.net/af/f7d492/00000000000000003b9b3067/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i6&v=3") format("woff"), url("https://use.typekit.net/af/f7d492/00000000000000003b9b3067/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i6&v=3") format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 600;
}

@font-face {
  font-family: "proxima-nova";
  src: url("https://use.typekit.net/af/705e94/00000000000000003b9b3062/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"), url("https://use.typekit.net/af/705e94/00000000000000003b9b3062/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"), url("https://use.typekit.net/af/705e94/00000000000000003b9b3062/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "proxima-nova";
  src: url("https://use.typekit.net/af/5c70f2/00000000000000003b9b3063/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff2"), url("https://use.typekit.net/af/5c70f2/00000000000000003b9b3063/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff"), url("https://use.typekit.net/af/5c70f2/00000000000000003b9b3063/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: "proxima-nova";
  src: url("https://use.typekit.net/af/6e816b/00000000000000003b9b3064/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("woff2"), url("https://use.typekit.net/af/6e816b/00000000000000003b9b3064/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("woff"), url("https://use.typekit.net/af/6e816b/00000000000000003b9b3064/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: "proxima-nova";
  src: url("https://use.typekit.net/af/5b5251/00000000000000003b9b3065/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i5&v=3") format("woff2"), url("https://use.typekit.net/af/5b5251/00000000000000003b9b3065/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i5&v=3") format("woff"), url("https://use.typekit.net/af/5b5251/00000000000000003b9b3065/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i5&v=3") format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 500;
}

.content{
  margin-top:80px;
  border-bottom: 1px solid #d8d8d8;
  padding: 60px 0 180px;
}

html, body{
  margin:0;
}

ol,
ul,
dl {
  margin-top: 1rem !important;

}

.w3-card{
  box-shadow: 0 4px 10px 0 rgba(0,0,0,0.2), 0 4px 20px 0 rgba(0,0,0,0.19);
  padding:10px;
}

.service-form .row{
  margin-bottom:20px;
}

@media (min-width: 767px){
  legend {
    font-size: 30px;
    line-height: 35px;
  }
}

legend {
  margin-bottom: 28px;
  font-family: raleway,proxima-nova,Arial,Helvetica Neue,Helvetica,sans-serif;
  font-size: 24px;
  font-weight: 800;
  /*line-height: 30px;*/
}

.card-title{
  text-align: center;
}

.list-group-item {
  list-style-type: none !important;
}

.btn-yellow{
  color: #fff !important;
  background-color: #fecb0a !important;
  border-color: #fecb0a !important;
  font-family: proxima-nova,Arial,Helvetica Neue,Helvetica,sans-serif !important;
  min-width: 162px;
  height: 62px;
  font-size: 16px;
  line-height: 60px;
}

.flex-column{
  display: flex;
  flex-direction: row;
}

.car-info span {
  margin-top:5px;
  margin-bottom:5px;
}


.how-container {
  padding: 50px 0 50px;
  font-size: 0;
  text-align: center;
}

.how-container h2 {
  margin-bottom: 55px;
  font-weight: 800;
}

@media (min-width: 767px) {
  .how-container h2 {
    margin-bottom: 73px;
    letter-spacing: .3px;
  }
}

.how-container article {
  margin: 0 auto 39px;
  text-align: center;
}

@media (min-width: 767px) {
  .how-container article {
    width: 33.33%;
  }
}

.how-container article .image {
  height: 109px;
  margin-bottom: 16px;
}

.how-container article .image img {
  height: 100%;
}

.how-container article .content {
  max-width: 330px;
  margin: auto;
}

.how-container article .content h3 {
  margin-bottom: 20px;
  font-family: raleway, proxima-nova, Arial, Helvetica Neue, Helvetica, sans-serif;
  font-weight: 800;
  line-height: 30px;
}

.how-container article .content p {
  margin: 0;
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  color: #191919;
}

.car-info-detail {
  margin: 0 0 15px;
  font-size: 14px;
  line-height: 24px;
  color: #9b9b9b;
}

.car-info li {
  margin: 0 0 15px;
  font-size: 14px;
  line-height: 24px;
  color: #9b9b9b;
}

.card-footer{
  display:flex;
  justify-content: flex-end;
}

.tab-pane{
  padding-top: 20px;
}

.site-header.open .hamburger {
  position: fixed;
  z-index: 999999999;
}

@media (max-width: 1022px) {
  .site-header.open nav {
    position: fixed;
    right: 0;
  }

  .content {
    margin-top:20px;
    padding-top:0;
  }
}

.car-info-card {
  margin-bottom: 20px;
}


@font-face {
  font-family: Gilroy;
  src: url("assets/fonts/Gilroy-Light.otf") format("opentype");
}

@font-face {
  font-family: Gilroy;
  font-weight: 800;
  src: url("assets/fonts/Gilroy-ExtraBold.otf") format("opentype");
}

@font-face {
  font-family: 'icomoon';
  src:  url('assets/fonts/icomoon.eot?u29alq');
  src:  url('assets/fonts/icomoon.eot?u29alq#iefix') format('embedded-opentype'),
  url('assets/fonts/icomoon.ttf?u29alq') format('truetype'),
  url('assets/fonts/icomoon.woff?u29alq') format('woff'),
  url('assets/fonts/icomoon.svg?u29alq#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-facebook:before {
  content: "\ea90";
}
.icon-instagram:before {
  content: "\ea92";
}
.icon-twitter:before {
  content: "\ea96";
}
.icon-linkedin2:before {
  content: "\eaca";
}

.header-spacing {
  margin-top: 30px;
}

@media (min-width: 1022px) {
  .header-spacing {
    margin-top: 120px !important;
  }

  .nav-button{
    font-size: 14px !important;
    font-weight: 400 !important;
  }

  .site-header ul li a.button{
    width: auto !important;
    height: 44px !important;
    line-height: 44px !important;

  }

  .button-li {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }

  .site-header nav li {
    margin-left: 20px !important;
  }

  .site-header nav ul{
    margin-bottom: 0 !important;
    margin-top: 0 !important;
  }

  .site-header nav[data-v-7cbe19f6]{
    top:0 !important;
  }
}

select.form-control {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}

.nav-button{
  font-size: 30px;
  font-weight: 700;
}
.site-header ul li a.button{
  width: 100%;
  height: 54px;
  line-height: 52px;

}

.select-arrow {
  position: absolute;
  pointer-events: none;
  top: 20px;
  right: 37px;
  font-size: 20px;
  color: #fecb0a;
  font-weight: 800;
  background-image: url('assets/images/basket/down_carot.png');
  background-size: contain;
  width:15px;
  height:15px;

  /*transform:scale(1.8,1);*/
}
.select-arrow-tyres {
  position: absolute;
  pointer-events: none;
  top: 42px;
  right: 30px;
  font-size: 20px;
  color: #fecb0a;
  font-weight: 800;
  background-image: url('assets/images/basket/down_carot.png');
  background-size: contain;
  width:15px;
  height:15px;

  /*transform:scale(1.8,1);*/
}



@media (max-width: 575px) {

  .addition-question {
    margin-bottom: 10px !important;
    font-size: 14px!important;
    font-weight: 600!important;
    color: #191919!important;
    margin-top:10px!important;
  }

}

.site-header.open .hamburger span:first-of-type {
  top: 50%;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.site-header.open .hamburger span:nth-of-type(3) {
  top: 50%;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.site-header.open .hamburger span:nth-of-type(2) {
  opacity: 0;
}

.warning, .btn-warning, .btn-outline-warning:hover{
  background-color: #fecb0a !important;
  border-color: #fecb0a !important;
}

.btn-outline-warning{
  border-color: #d2d2d2 !important;
}
